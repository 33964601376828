import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ADMIN_SERVICE_API_URL, APP_USERS_SERVICE_API_URL } from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  getAllFilterTypes(): Observable<Array<any>> {
    return this.http.get<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_all_filter_types');
  }

  getPurchaseOrders(postData: any) {
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_purchase_orders', postData, { observe: 'response' });
  }

  getShipments(paginationData: any) {
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_shipments', paginationData, { observe: 'response' });
  }

  getShipmentTimeline(postData: any) {
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_shipment_timeline', postData, { observe: 'response' });
  }

  setColumnFilters(filterData: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/set_column_filters', filterData, { observe: 'response' });
  }

  getColumnPopupData(dataBody: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/get_column_popup_data', dataBody, { observe: 'response' })
  }

  getSavedDynamicQueryFilter(postData: any) {
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_dynamic_query_filter', postData, { observe: 'response' });
  }

  getDashboardKpis(postData: any){
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_dashboard_kpis', postData, { observe: 'response' });
  }

  getDashboardMapLocations(postData: any) {
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_dashboard_map_locations', postData, { observe: 'response' });
  }

  getDashboardMapLocationSupplyChainDetails(postData: any) {
    return this.http.post<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_dashboard_map_location_supply_chain_details', postData, { observe: 'response' });
  }

  getAlertsAndDetails(postData: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/get_alerts_and_details', postData, { observe: 'response' });
  }

  getAlertDetails(postData: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/get_alert_details', postData, { observe: 'response' });
  }

  getFileRefreshLatestDate() {
    return this.http.get<any>(APP_USERS_SERVICE_API_URL + '/get_file_refresh_latest_date', { observe: 'response' });
  }

  getReportsAndAnalysisData(postBody: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/get_reports_and_analysis_data', postBody, { observe: 'response' });
  }
}
