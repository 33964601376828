import { Component } from '@angular/core';

@Component({
  selector: 'rc-page-not-found-404',
  templateUrl: './page-not-found-404.component.html',
  styleUrls: ['./page-not-found-404.component.css']
})
export class PageNotFound404Component {

}
