<!-- <div class="container text-center">
    <h1>So sorry,</h1>
    <p>we couldn't find the page you requested...</p>
    <a routerLink="/" class="btn btn-primary">Return to Dashboard</a>
</div> -->

<div class="container text-center">
<div class="watermark">404</div>
<div class="container error-container">
    <div class="row justify-content-center">
        <div class="col-md-10">
            <h1 class="mt-3">So sorry,</h1>
            <p class="text-muted">we couldn't find the page you requested...</p>
            <a href="/" class="btn btn-primary mt-3">Return to Dashboard <i class="fa fa-arrow-right"
                    aria-hidden="true"></i></a>
        </div>
    </div>
</div>
</div>