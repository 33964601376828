import { Injectable } from '@angular/core';

export interface PaginationData {
  currentPage: number;
  pageSize: number;
  sortOrder: string;
  sortColumn: string;
}

export interface ShipmentStage {
  stageId: number,
  stageName: string,
  stageType: string,
  stageLabel: string,
  stageDate: Date | null,
  status: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public user: any;

  public filterTypesMap: any = {
    po_filters: 'Order',
    shipment_filters: 'Shipment',
    dashboard_filters: 'Dashboard',
    alert_filters: 'Alert'
  };

  public dynamicFilterInfoPopoverTextMap: any = {
    generic: "If ‘All’ is selected, then only the data that satisfy all of the below conditions will be shown. If 'Any' is selected, then the data satisfying at least one of these conditions will be shown.",
    po_filters: "If ‘All’ is selected, then only Orders that satisfy all of the below conditions will be shown. If 'Any' is selected, then Orders satisfying at least one of these conditions will be shown.",
    shipment_filters: "If ‘All’ is selected, then only Shipments that satisfy all of the below conditions will be shown. If 'Any' is selected, then Shipments satisfying at least one of these conditions will be shown.",
    dashboard_filters: "If ‘All’ is selected, then the Dashboard will show data based on only the Orders and Shipments where all of the below conditions are true. If ‘Any’ is selected, then only one of these conditions must be satisfied.",
    alert_filters: "If ‘All’ is selected, then the Alert will show data based on only the Orders / Shipments where all of the below conditions are true. If ‘Any’ is selected, then only one of these conditions must be satisfied."
  }

  public dataTypeMap: any = {
    CUSTOMER: 'Customer',
    BUSINESS_BRAND: 'Business Brand',
    PARTNER: 'Partner',
    PORT: 'Port',
    ORDER: 'Order',
    NIF: 'NIF',
    ALB: 'ALB',
    SHIPMENT: 'Shipment',
    MSS: 'MSS',
    SALES: 'SALES'
  };

  public orderColumnMappings: any = {
    brand: 'Brand',
    cupc: 'Case UPC',
    category: 'Category',
    total_dollars: 'Cost ($)',
    customer: 'Customer',
    in_dc_date: 'In DC Date',
    item: 'Item Name',
    iupc: 'Item UPC',
    master_cases_required: 'Master Cases',
    status: 'Order Status',
    po_number: 'PO Number',
    total_retail_dollars: 'Selling Price ($)',
    shipping_address: 'Ship-to Address',
    shipping_cases_required: 'Shipping Cases',
    shipping_country: 'Ship-to Country',
    shipping_city: 'Ship-to City',
    shipping_state: 'Ship-to State',
    supplier: 'Supplier Name',
    type: 'Type',
    ordered_units: 'Units Ordered',
  };

  public shipmentColumnMappings: any = {
    discharge_port_ATA: 'Actual At Discharge Port Date',
    ramp_ATA: 'Actual At Ramp Date',
    loading_port_ATD: 'Actual Departure Date',
    dc_ATA: 'Actual In DC Date',
    receiving_appt_date: 'Appointment Date',
    discharge_current_eta: 'At Discharge Port Current ETA',
    bl_number: 'BL Number',
    cargo_ready_date: 'Cargo Ready Date',
    carrier: 'Carrier',
    customer: 'Customer',
    stage: 'Delivery Status',
    discharge_port: 'Discharge Port',
    discharge_port_eta: 'Estimated At Discharge Port Date',
    ramp_eta: 'Estimated At Ramp Date',
    etd: 'Estimated Departure Date',
    eta_dc: 'Estimated In DC Date',
    final_destination: 'Final Destination',
    in_dc_date: 'In DC Date',
    inspection_date: 'Inspection Date',
    inspection_release_date: 'Inspection Release Date',
    notes: 'Notes',
    po_number: 'PO Number',
    port_of_loading: 'Port of Loading',
    ship_from_date: 'Ship From Date',
    ship_to_date: 'Ship To Date',
    shipping_instruction_release_date: 'Shipping Instructions Date',
    master_cases_required: 'Total Cases',
    supplier: 'Vendor',
  };

  public poStageStatusMappings: any = {
    po_recieved: {
      "rules": [
        { "field": "stage", "value": ['DELIVERED'], "operator": "is one of" }
      ],
      "condition": "and"
    },
    po_recieved_on_time: {
      "rules": [
        { "field": "stage", "value": ['DELIVERED'], "operator": "is one of" },
        { "field": "status", "value": ['ON_TIME'], "operator": "is one of" }
      ],
      "condition": "and"
    },
    po_recieved_delayed: {
      "rules": [
        { "field": "stage", "value": ['DELIVERED'], "operator": "is one of" },
        { "field": "status", "value": ['DELAYED'], "operator": "is one of" }
      ],
      "condition": "and"
    },
    po_open: {
      "rules": [
        { "field": "stage", "value": ['SHIPPED', 'NOT_SHIPPED'], "operator": "is one of" }
      ],
      "condition": "and"
    },
    po_open_on_time: {
      "rules": [
        { "field": "stage", "value": ['SHIPPED', 'NOT_SHIPPED'], "operator": "is one of" },
        { "field": "status", "value": ['ON_TIME'], "operator": "is one of" }
      ],
      "condition": "and"
    },
    po_open_delayed: {
      "rules": [
        { "field": "stage", "value": ['SHIPPED', 'NOT_SHIPPED'], "operator": "is one of" },
        { "field": "status", "value": ['DELAYED'], "operator": "is one of" }
      ],
      "condition": "and"
    }
  }

  public valueFormatMap: any = {
    ON_TIME: 'On Time',
    DELAYED: 'Delayed',
    DELIVERED: 'Delivered',
    SHIPPED: 'Shipped',
    NOT_SHIPPED: 'Not Shipped',
    po_filters: 'Order',
    shipment_filters: 'Shipment',
    dashboard_filters: 'Dashboard',
    alert_filters: 'Alert',
    GENERAL_ACCESS: 'General Access',
    FILE_UPLOAD: 'File Upload',
    RESICHAIN_EMPLOYEE: 'ResiChain Employee',
    STANDARD_USER: 'Standard User',
    ADMIN: 'Administrator',
    CUSTOMER: 'Customer',
    BUSINESS_BRAND: 'Business Brand',
    PARTNER: 'Partner',
    PORT: 'Port',
    ORDER: 'Order',
    NIF: 'NIF',
    ALB: 'ALB',
    SHIPMENT: 'Shipment',
    MSS: 'MSS',
    SALES: 'SALES'
  }

  public reportsAndAnalysisColumnMappings: any = {
    pivot: 'Pivot',
    brand: 'Brand',
    category: 'Category',
    subcategory: 'Subcategory',
    item: 'Item',
    gross_sales: 'Gross Sales',
    net_sales: 'Net sales',
    avg_disc: 'Avg Disc (%)',
    units_shipped: 'Units Shipped',
    units_sold: 'Units Sold',
    sell_thru_percentage: 'Sell Thru (%)',
    avg_price: 'Avg Price',
    retail_buy: 'Retail Buy',
    spend: 'Spend',
    gp_percentage: 'GP (%)',
    ordered_units: 'Units Ordered',
    order_shipped_percentage: '% Order Shipped'
  };

  public reportsAndAnalysisTooltipTextMappings: any = {
    pivot: 'Pivot',
    brand: 'Brand',
    category: 'Category',
    subcategory: 'Subcategory',
    item: 'Item',
    gross_sales: 'Gross Sales',
    net_sales: 'Net sales',
    avg_disc: 'Avgerage Discount',
    units_shipped: 'Units Shipped',
    units_sold: 'Units Sold',
    sell_thru_percentage: 'Sell-through % (Units Sold / Units Shipped)',
    avg_price: 'Average Price (Net Sales / Units Sold)',
    retail_buy: 'Retail Dollars',
    spend: 'Retailer\'s Spend',
    gp_percentage: 'Gross Profit % (Spend / Retail Buy)',
    ordered_units: 'Units Ordered',
    order_shipped_percentage: 'Percentage of the original order that has been shipped',
  };

  secondaryDynamicQueryFilterStringMap: any = {
    shipment_id: 'Shipments'
  }

  public configurationIssuesColumnMappings: any = {
    failed_row: 'File Data',
    fail_reason: 'Failure Reason',
    reingest_try: '# Failures',
    reingest_dt: 'Latest Failure'
  }

  public itemAliasesColumnMappings: any = {
    object_type: 'Object Type',
    name: 'name',
    modified_on: 'Last Edited'
  };

  public attributeGroupColumnMappings: any = {
    title: 'ID',
    current_value: 'Current Value',
    modified_on: 'Last Updated',
    alias_value: 'Alias',
    revision_value: 'Revised Value'
  }

  public addEntityColumnMappings: any = {
    name: 'Subscriber',
    web_domain: 'Web Domain',
    primary_email_domain: 'Primary Email Domain',
    alt_email_domain: 'Alternate Email Domain',
    created_on: 'Created On'
  };

  public savedFiltersColumnMappings: any = {
    name: 'Name',
    filter_type: 'Type',
    created_on: 'Created On',
    created_by_email: 'Created By'
  };

  public apiKeysColumnMappings: any = {
    token_type: 'API Type',
    api_token: 'Token',
    expires_on: 'Expiration Date'
  }

  public addUserColumnMappings: any = {
    email: 'User',
    role: 'Role',
    is_active: 'Active',
    created_on: 'Created On'
  }

  public uploadFilesColumnMappings: any = {
    mapping_object_type: 'Type',
    modified_on: 'Last Edited',
    modified_by: 'Edited By',
    files_count: '# Files'
  }

  dynamicQueryFilterMetaData: Array<any> = [];

  constructor() { }

  getCurrentDate() {
    let currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  }

  private dynamicFilterData: any = {};

  setDynamicFilterData(data: any) {
    this.dynamicFilterData = data;
    // console.log('setDynamicFilterData', this.dynamicFilterData);

  }

  getDynamicFilterData() {
    // console.log('getDynamicFilterData', this.dynamicFilterData);

    return this.dynamicFilterData;
  }

  clearDynamicFilterData() {
    this.dynamicFilterData = {};
  }

  isObjectEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  computeDashboardDynamicQueryFilters(stageStatus: string, dynamicQueryFilters: any = {}, location_details: any = null) {
    let computedDynamicQueryFilter = this.poStageStatusMappings[stageStatus];

    if (location_details != null && location_details.location_type == 'Port') {
      computedDynamicQueryFilter.rules.push({ "field": "port_used", "value": [location_details.id], "operator": "is one of" })
    } else if (location_details != null && location_details.location_type == 'DC') {
      computedDynamicQueryFilter.rules.push({ "field": "dc_used", "value": [location_details.id], "operator": "is one of" })
    }

    // If there are existing dynamic query filters, merge them
    if (dynamicQueryFilters.rules && dynamicQueryFilters.rules.length > 0) {
      computedDynamicQueryFilter = {
        ...computedDynamicQueryFilter,
        condition: dynamicQueryFilters.condition,
        rules: computedDynamicQueryFilter.rules.concat(dynamicQueryFilters.rules)
      };
    }

    // console.log("computedDynamicQueryFilter", computedDynamicQueryFilter);
    return computedDynamicQueryFilter;
  }

  computeCombinedDynamicQueryFilters(dynamicQueryFilters: any = {}, secondaryDynamicQueryFilters: any = {}) {
    // let computedDynamicQueryFilter = dynamicQueryFilters;

    // // If there are secondary dynamic query filters, merge them
    // if (secondaryDynamicQueryFilters.rules && secondaryDynamicQueryFilters.rules.length > 0) {
    //   computedDynamicQueryFilter = {
    //     ...computedDynamicQueryFilter,
    //     condition: !!dynamicQueryFilters.condition ? dynamicQueryFilters.condition : secondaryDynamicQueryFilters.condition,
    //     rules: !!computedDynamicQueryFilter.rules ? computedDynamicQueryFilter.rules.concat(secondaryDynamicQueryFilters.rules) : secondaryDynamicQueryFilters.rules
    //   };
    // }

    // // console.log("computedDynamicQueryFilter", computedDynamicQueryFilter);
    // return computedDynamicQueryFilter;
    dynamicQueryFilters.condition = dynamicQueryFilters.condition || "and";
    dynamicQueryFilters.rules = dynamicQueryFilters.rules || [];
    secondaryDynamicQueryFilters.condition = secondaryDynamicQueryFilters.condition || "and";
    secondaryDynamicQueryFilters.rules = secondaryDynamicQueryFilters.rules || [];

    let combinedDynamicQueryFilters: any = {
      condition: "and",
      rules: []
    };

    // Check if the dynamicQueryFilters condition is "or"
    if (dynamicQueryFilters.condition === "or") {
      if (dynamicQueryFilters.rules.length === 1) {
        // Scenario 1: Only one rule in dynamicQueryFilters.rules
        combinedDynamicQueryFilters.rules = [
          ...dynamicQueryFilters.rules,
          ...secondaryDynamicQueryFilters.rules
        ];
      } else if (dynamicQueryFilters.rules.length > 1) {
        // Scenario 2: More than one rule in dynamicQueryFilters.rules
        combinedDynamicQueryFilters.rules = [
          ...secondaryDynamicQueryFilters.rules,
          {
            condition: "or",
            rules: dynamicQueryFilters.rules
          }
        ];
      }
    } else {
      // If condition is "and", merge rules directly
      combinedDynamicQueryFilters.rules = [
        ...dynamicQueryFilters.rules,
        ...secondaryDynamicQueryFilters.rules
      ];
    }

    return combinedDynamicQueryFilters;
  }


  combinedDynamicQueryFiltersSeperator(combinedDynamicQueryFilters: any = {}, dynamicQueryFilterMetaData: Array<any> = []) {

    // Extract keys from dynamicQueryFilterMetaData
    const metaDataKeys = dynamicQueryFilterMetaData.map(item => item.key);

    // Recursive function to handle nested rules
    function separateRules(rules: any[], parentCondition: string) {
      const dynamicRules: Array<any> = [];
      const secondaryRules: Array<any> = [];

      let nestedDynamicCondition = parentCondition; // Track condition for dynamicRules

      rules.forEach(rule => {
        if (rule.condition && rule.rules) {
          // Recursively separate nested rule sets
          const separatedNestedRules = separateRules(rule.rules, rule.condition);

          // If nested rules have dynamic fields, add them to dynamicRules and set condition
          if (separatedNestedRules.dynamicRules.length > 0) {
            dynamicRules.push({
              condition: rule.condition,
              rules: separatedNestedRules.dynamicRules
            });
            nestedDynamicCondition = rule.condition; // Use nested condition
          }
          // If nested rules have secondary fields, add them to secondaryRules
          if (separatedNestedRules.secondaryRules.length > 0) {
            secondaryRules.push({
              condition: rule.condition,
              rules: separatedNestedRules.secondaryRules
            });
          }
        } else {
          // Separate based on whether rule.field is in metaDataKeys
          if (metaDataKeys.includes(rule.field)) {
            dynamicRules.push(rule);
          } else {
            secondaryRules.push(rule);
          }
        }
      });

      return { dynamicRules, secondaryRules, nestedDynamicCondition };
    }

    // Start separating rules from the top level
    const { dynamicRules, secondaryRules, nestedDynamicCondition } = separateRules(
      combinedDynamicQueryFilters.rules,
      combinedDynamicQueryFilters.condition
    );

    // Assemble the separated dynamic and secondary query filters
    let dynamicQueryFilters = {
      condition: dynamicRules.length > 1 ? nestedDynamicCondition : combinedDynamicQueryFilters.condition,
      rules: dynamicRules
    };

    if (dynamicQueryFilters.rules.length > 0 && !!dynamicQueryFilters.rules[0].rules && dynamicQueryFilters.rules[0].rules.length > 1) {
      dynamicQueryFilters.condition = dynamicQueryFilters.rules[0].condition;
      dynamicQueryFilters.rules = dynamicQueryFilters.rules[0].rules;
    }


    const secondaryDynamicQueryFilters = {
      condition: combinedDynamicQueryFilters.condition,
      rules: secondaryRules
    };

    // console.log('combinedDynamicQueryFiltersSeperator', dynamicQueryFilters, secondaryDynamicQueryFilters);

    return { dynamicQueryFilters, secondaryDynamicQueryFilters };
  }

}
